import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import SnackBar from 'src/context/snackbar'
import { getPromoCodeFromUrl } from 'src/utils/filters'
import { isValidEmail, isValidUrl } from 'src/utils/validation'
import CodeEmailSubmitPage from './code-email-input-form'
import SubscriptionInfoPage from './subscription-info'
import {
  recordUserInteraction,
  userSubmittedSelfSignUpDetails,
  userSubmittedSelectedPackage,
  userSelectedPackage,
} from 'src/services/posthog'
import { getJwtPayload } from 'src/auth/payload'
import ConfirmationModal from '../upgrade-confirmation-modal'

// ======
// TYPES
// ======
type SelfSignUpLandingPageProps = {
  step: string
  handleSetStep?: (step: string) => void
}

// =========
// COMPONENT
// =========
const SelfSignUpLandingPage = ({ step, handleSetStep }: SelfSignUpLandingPageProps) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = SnackBar.Consumer()
  const urlCode = getPromoCodeFromUrl()

  const { userId, subscriptionPlan } = getJwtPayload()
  const { subscriptionPackagesInfo } = useSelector((state) => state.ui)

  const [email, setEmail] = useState('')
  const handleSetEmail = (email: string) => setEmail(email)

  // handle user input errors
  const [emailError, setEmailError] = useState('')
  const [userEnteredCodeError, setUserEnteredCodeError] = useState('')
  const [orgNameError, setOrgNameError] = useState('')
  const [orgDomainError, setOrgDomainError] = useState('')

  // loading state
  const [loadingCustomerCouponInfo, setLoadingCustomerCouponInfo] = useState(false)
  const [loadingClientSecret, setLoadingClientSecret] = useState({
    Core: false,
    Professional: false,
    Enterprise: false,
  })

  // upgrade modal
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)

  const handleConfirmationModalOpen = (state: boolean) => {
    setIsConfirmationModalOpen(state)
  }

  const handleSubmitCodeAndEmail = async (
    userEnteredCode: string,
    userEnteredEmail: string,
    orgName: string,
    orgDomain: string
  ) => {
    setEmailError('')
    setUserEnteredCodeError('')
    setOrgNameError('')
    setOrgDomainError('')

    // check email and code entered (or collected from url)
    const isEmail = isValidEmail(email)
    const isValidDomain = isValidUrl(orgDomain)

    if (!isEmail) setEmailError('Please enter a valid email')
    if (!urlCode && !userEnteredCode) setUserEnteredCodeError('Please enter a code')
    if (!orgName) setOrgNameError('Please enter your organisation name')
    if (!isValidDomain)
      setOrgDomainError(orgDomain?.length ? 'Please enter a valid domain' : 'Please enter your organisation domain')

    const isError = !isEmail || (!urlCode && !userEnteredCode) || !orgName || !isValidDomain
    if (isError) return

    recordUserInteraction(userSubmittedSelfSignUpDetails)
    setLoadingCustomerCouponInfo(true)

    try {
      const { data } = await axios.post('/payment-portal-lite/customer-coupon', {
        email: userEnteredEmail,
        code: urlCode ?? userEnteredCode,
      })

      const couponPercentOffFromResponse = (100 - data?.couponPercentOff) / 100
      dispatch({
        type: 'SUBSCRIPTION_PACKAGES_INFO',
        payload: {
          promoCode: urlCode ?? userEnteredCode,
          customerId: data?.customerId,
          couponId: data?.couponId,
          partnerBrandingLogoUrl: data?.partnerBrandingLogoUrl,
          couponPercentOff: couponPercentOffFromResponse,
          organisationName: orgName,
          organisationDomain: orgDomain,
          email: email,
          products: data?.products,
        },
      })
      handleSetStep('subscription-info')
    } catch (error) {
      console.log('handle submit code and email error: ', error)
      if (
        error?.response?.data?.message === `Please contact us at support@orpheus-cyber.com to upgrade` ||
        error?.response?.data?.message === 'Referral code not recognised'
      ) {
        enqueueSnackbar(error?.response?.data?.message, {
          variant: 'success',
        })
      } else
        enqueueSnackbar('Something went wrong, please try again later', {
          variant: 'error',
        })
    } finally {
      setLoadingCustomerCouponInfo(false)
    }
  }

  const handleRequestCheckout = async (
    productName: string,
    productId: string,
    priceId: string,
    subscriptionPrice: number,
    country: string = null,
    address: string = null,
    postalCode: string = null,
    unusedPrice: string
  ) => {
    setLoadingClientSecret((prevStates) => ({
      ...prevStates,
      [productName]: true,
    }))

    recordUserInteraction(userId ? userSelectedPackage(productName) : userSubmittedSelectedPackage(productName))
    try {
      // This if condition code may move into checkout form in future
      if (subscriptionPackagesInfo.trialPeriodLength) {
        const { data } = await axios.post('/payment-portal-lite/create-user', {
          receiptEmail: email,
          organisationName: subscriptionPackagesInfo.organisationName,
          organisationDomain: subscriptionPackagesInfo.organisationDomain,
          includesFreeTrial: true,
        })
        dispatch({ type: 'SUBSCRIPTION_PACKAGES_INFO', payload: {} })
        history.push(`/redeem/success${data?.userExists ? '?userExists=true' : ''}`)
      } else {
        dispatch({
          type: 'SELECTED_SUBSCRIPTION_PACKAGE',
          payload: {
            selectedSubscriptionPackage: {
              unusedPrice: unusedPrice,
              description: productName,
              productId: productId,
              monthlyPriceId: priceId,
              monthlySubscriptionPrice: subscriptionPrice,
              country,
              address,
              postalCode,
            },
          },
        })
        if (userId && subscriptionPlan?.type) {
          handleConfirmationModalOpen(true)
        } else if (userId) {
          history.push('/your-report/checkout')
        } else history.push('/redeem/checkout')
      }
    } catch (error) {
      console.log(':: error', error)
      enqueueSnackbar('Something went wrong, please try again later', {
        variant: 'error',
      })
    } finally {
      setLoadingClientSecret((prevStates) => ({
        ...prevStates,
        [productName]: false,
      }))
    }
  }

  if (step === 'landing-page')
    return (
      <CodeEmailSubmitPage
        urlCode={urlCode}
        userEnteredCodeError={userEnteredCodeError}
        emailError={emailError}
        orgNameError={orgNameError}
        orgDomainError={orgDomainError}
        loadingCustomerCouponInfo={loadingCustomerCouponInfo}
        handleSetEmail={handleSetEmail}
        email={email}
        handleSubmitCodeAndEmail={handleSubmitCodeAndEmail}
      />
    )

  if (step === 'subscription-info')
    return (
      <>
        <SubscriptionInfoPage handleRequestCheckout={handleRequestCheckout} loadingClientSecret={loadingClientSecret} />

        {isConfirmationModalOpen && (
          <ConfirmationModal
            isOpen={isConfirmationModalOpen}
            onClose={() => handleConfirmationModalOpen(false)}
            title="Confirm Upgrade"
          />
        )}
      </>
    )

  return null
}

export default SelfSignUpLandingPage
